const env = {
  certificateURL: 'https://test.certificados.certika.co/share/certificate/',
  certificatesURL: 'https://test.certificados.certika.co/share/certificates/',
  certificatesURLMin: 'https://test.certificados.minciencias.gov.co/share/certificates/',
  apiURL: 'https://test.api.certika.co/api',
  linkedinShare: 'https://test.app.certika.co/share',
  linkedinShareCertificate: 'https://test.app.certika.co/share/certificate',
  documentURL: 'https://test.certificados.certika.co/share/document/',
  linkedinShareDocument: 'https://test.app.certika.co/share/document',
  carnetURL: 'https://test.certificados.certika.co/share/carnet/',
  linkedinShareCarnet: 'https://test.app.certika.co/share/carnet',
  certikaURL: 'https://test.app.certika.co',
  mincienciasURL: 'https://test.app.minciencias.gov.co',
  faviconCertika: 'https://certika-s3-bucket.s3.amazonaws.com/281d313f46d0ee0b5c796424aeca7fed/images/favicon.png',
  faviconMinciencias: 'https://certika-s3-bucket.s3.amazonaws.com/5acedcf635f23c1a392b2df745e77103/images/favicon.png',
  idMinciencias: 17,
  idEan: 16,
  idCmlogica: 13,
  idOrquidea: 20,
  TEST_PAYU: true,
  API_KEY_PAYU: '8hbc269ueeqB5N2XcKK13gL3cB',
  merchantId: '929375',
  accountId: '936682',
  keyIpData: 'fc1128a1de77baa17b6a99bb67a08061c386a72b49b2ffc3a11c81b3',
};

export { env };
