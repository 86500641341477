import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  FormControl,
  TextField,
} from '@material-ui/core';
import { FindInPageOutlined, DeleteForeverOutlined, FileDownloadOutlined, MailOutlined } from '@mui/icons-material';
import Table from '../../../components/Table';
import SkeletonTables from '../../../components/SkeletonTable';
import ModuleHeader from '../../../components/ModuleHeader';
import Search from '../../../components/SearchBar';
import Backdrop from '../../../components/Backdrop';
import colors from '../../../assets/styles/colors';
import EditIcon from '../../../assets/static/icons/EditIcon';
import axios from '../../../api';
import { changePage } from '../../../actions';
import { changeBreadcrump, sortData, getComparator } from '../../../actions';
import { encrypt } from '../../../utils/crypt';
import downloadFile from '../../../utils/downloadFile';
import { useTranslation } from 'react-i18next';

function GroupsBadgesIssued(props) {
  const { page, rowsPerPage, permission, changePage, changeBreadcrump, user, organizationId, searchValue } = props;
  const history = useHistory();
  const classes = useStyles();
  const [groupsBadgesIssued, setGroupsBadgesIssued] = useState([]);
  const [filtro, setFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [form, setForm] = useState({});
  const [ordenDirection, setOrdenDirection] = useState('');
  const [valueToOrderBy, setValueToOrderBy] = useState('');
  const [countItems, setCountItems] = useState();

  const [t] = useTranslation(['alerts', 'tables', 'buttons', 'breadCrumps', 'dialogs']);

  useEffect(() => {
    if (permission.includes('Leer')) {
      loadData().catch(() => {
        history.push('/500');
        window.location.reload();
      });
    } else {
      history.push('/dashboard');
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    if (!searchValue || searchValue === '') {
      await getGroupsBadgesIssued();
    }
    changeBreadcrump(t('breadCrumps:breadCrumps.groups-badge-issued', { returnObjects: true }));
    setLoading(false);
  };

  const isSuper =
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
    user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
      ? true
      : false;

  const getParams = (page, perPage) => {
    const limit = perPage ? perPage : 10;
    const offset = page ? page * perPage : 0;
    const params = { limit: limit, offset: offset };

    if (!isSuper) {
      params.id_organizaciones = organizationId;
    }

    return params;
  };

  const getGroupsBadgesIssued = async () => {
    try {
      const params = getParams(0, rowsPerPage);
      params.estado = 1;
      const { data } = await axios.post(`/groupsBadgesIssued/getGroups`, params);
      setGroupsBadgesIssued(data.groups?.rows);
      setFiltradas(data.groups?.rows);
      setCountItems(data.groups?.count);
    } catch (error) {
      history.push('/500');
      window.location.reload();
    }
  };

  const changePagination = async (newPage, perPage) => {
    setLoading(true);
    const params = getParams(newPage, perPage);
    const { data } = await axios.post(`/groupsBadgesIssued/getGroups`, params);

    const array = [
      ...groupsBadgesIssued,
      ...data.groups?.rows.filter((row) => !groupsBadgesIssued.find((item) => item.id === row.id)),
    ];

    setGroupsBadgesIssued(array);
    setFiltradas(array);
    setLoading(false);
  };

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleClick = (e, id, action) => {
    switch (action) {
      case 'delete':
        modalDelete(id);
        break;
      default:
        break;
    }
  };

  const modalDelete = (id) => {
    Swal.fire({
      text: t('alerts:alerts.revoke-group'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendDelete(id);
      }
    });
  };

  const sendDelete = async (id) => {
    try {
      const { value: text } = await Swal.fire({
        text: t('alerts:alerts.revoke-message'),
        input: 'textarea',
        inputPlaceholder: t('alerts:alerts.message-here'),
        inputAttributes: {
          'aria-label': t('alerts:alerts.message-here'),
        },
        showCancelButton: true,
        confirmButtonText: t('buttons:buttons.send'),
        cancelButtonText: t('buttons:buttons.cancel'),
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'custom-button-confirm',
          cancelButton: 'custom-button-cancel',
        },
        inputValidator: (valor) => {
          if (!valor) {
            return t('alerts:alerts.write-message');
          }
        },
      });
      if (text) {
        setLoadingBackdrop(true);
        const { data } = await axios.delete(`/badgesIssued/revoke/${id}`, { data: { mensaje: text } });
        setGroupsBadgesIssued(groupsBadgesIssued.filter((item) => item.id !== id));
        setFiltradas(groupsBadgesIssued.filter((item) => item.id !== id));
        if (data) {
          setLoadingBackdrop(false);
          Swal.fire({
            text: t('alerts:alerts.revoke'),
            icon: 'success',
            iconColor: colors.primary,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.revoke-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const modalForwadEmail = () => {
    setOpenDialog(false);
    Swal.fire({
      title: t('alerts:alerts.badge-no-accepted'),
      text: t('alerts:alerts.resend'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmail();
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  const sendForwadEmail = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/badgesIssued/forwardEmail/${groupId}`);
      if (data.issued.length > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.badges-to-accept'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const modalForwadEmailSignup = () => {
    setOpenDialog(false);
    Swal.fire({
      title: t('alerts:alerts.create-account'),
      text: t('alerts:alerts.email-account'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmailSignup();
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  const sendForwadEmailSignup = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/badgesIssued/forwardEmail/signup/${groupId}`);
      if (data.issued > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.no-accouts'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const modalForwadEmailShare = () => {
    setOpenDialog(false);
    Swal.fire({
      title: t('alerts:alerts.share-badge'),
      text: t('alerts:alerts.email-share'),
      icon: 'warning',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.accept'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        sendForwadEmailShare();
      } else if (result.isDismissed) {
        setOpenDialog(true);
      }
    });
  };

  const sendForwadEmailShare = async () => {
    try {
      setLoadingBackdrop(true);
      const { data } = await axios.get(`/badgesIssued/forwardEmail/share/${groupId}`);
      if (data.issued > 0) {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.email-sent'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        setLoadingBackdrop(false);
        Swal.fire({
          text: t('alerts:alerts.no-share-badge'),
          icon: 'error',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      setLoadingBackdrop(false);
      Swal.fire({
        text: t('alerts:alerts.email-sent-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const toLink = (e, id) => {
    changePage(0);
    history.push(`/badgesIssued/badges/${id}`);
  };

  const delete_permission = (id) => {
    if (permission.includes('Eliminar')) {
      return (
        <TableCell align="center">
          <Tooltip title={t('tables:table.tooltip.revoke')}>
            <IconButton aria-label="delete" onClick={(e) => handleClick(e, id, 'delete')}>
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    }
  };

  const sendMails_permission = (id) => {
    if (permission.includes('Enviar correos')) {
      return (
        <TableCell align="center">
          <Tooltip title={t('tables:table.tooltip.sent-email')}>
            <IconButton aria-label="emails" onClick={() => openDialogBadge(id)}>
              <MailOutlined />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    }
  };

  const edit_permission = (id) => {
    if (permission.includes('Editar')) {
      return (
        <TableCell align="center">
          <Tooltip title={t('tables:table.tooltip.edit')}>
            <IconButton aria-label="edit" onClick={() => openEdit(id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    }
  };

  const openDialogBadge = (groupId) => {
    setGroupId(groupId);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const openEdit = (groupId) => {
    const group = groupsBadgesIssued?.filter((group) => group.id === groupId);
    setForm({
      ...group[0],
    });
    setOpenEditDialog(true);
  };

  const closeEdit = () => {
    setOpenEditDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    closeEdit();
    try {
      const { data } = await axios.put(`/groupsBadgesIssued/${form.id}`, { nombre: form.nombre });
      if (data) {
        Swal.fire({
          text: t('alerts:alerts.edited'),
          icon: 'success',
          iconColor: colors.primary,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      getGroupsBadgesIssued();
    } catch (error) {
      Swal.fire({
        text: t('alerts:alerts.edited-error'),
        icon: 'error',
        iconColor: colors.primary,
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/500');
      window.location.reload();
    }
  };

  const columns = () => {
    return t('tables:table.columns-group-badges-issued', { returnObjects: true });
  };

  return (
    <>
      <ModuleHeader />
      <Search
        tableName="groupIssued"
        type="badges"
        items={groupsBadgesIssued}
        setItems={setFiltradas}
        setCountItems={setCountItems}
        changePage={changePage}
        loadData={loadData}
        setLoading={setLoading}
        organizationId={
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 1 ||
          user.data_user.gruposUsuariosOrganizaciones[0].id_grupos_usuarios === 14
            ? null
            : organizationId
        }
      />
      <Table
        columns={columns()}
        rows={countItems}
        setDirection={setOrdenDirection}
        setOrderBy={setValueToOrderBy}
        loading={loading}
        changePagination={changePagination}
      >
        <TableBody>
          {loading ? (
            <SkeletonTables columns={columns()} photo={0} />
          ) : filtro.length > 0 ? (
            <>
              {sortData(filtro, getComparator(ordenDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={`row${index}`}>
                    <TableCell align="center">{moment(row.fecha_emision).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">{`${row.nombre}`}</TableCell>
                    <TableCell align="center">{`${row.organizaciones?.nombre || row.nombre_organizacion}`}</TableCell>
                    <TableCell align="center">{`${row.usuarios?.primer_nombre || row.primer_nombre} ${
                      row.usuarios?.primer_apellido || row.primer_apellido
                    }`}</TableCell>
                    <TableCell align="center">
                      {row.BadgesIssued}
                      <Tooltip title={t('tables:table.tooltip.view-badge')}>
                        <IconButton aria-label="detalles" onClick={(e) => toLink(e, encrypt(row.id))}>
                          <FindInPageOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {edit_permission(row.id)}
                    {sendMails_permission(row.id)}
                    <TableCell align="center">
                      {permission.includes('Descargar') && (
                        <Tooltip title={t('buttons:buttons.download')}>
                          <IconButton aria-label="download" onClick={() => downloadFile(row.archivo)}>
                            <FileDownloadOutlined />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>

                    {delete_permission(row.id)}
                  </TableRow>
                ))}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="8">
                {t('tables:table.empty-table')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>{t('dialogs:dialog.choose-option')}</DialogTitle>
        <DialogContent dividers>
          <div className={`text-center ${classes.containerButton}`}>
            <Button
              disableElevation
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={modalForwadEmail}
            >
              {t('buttons:buttons.reminder')}
              <br />
              {t('buttons:buttons.badge-no-accepted')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={modalForwadEmailSignup}
            >
              {t('buttons:buttons.reminder')}
              <br />
              {t('buttons:buttons.create-account')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              className={`my-2 ${classes.button}`}
              onClick={modalForwadEmailShare}
            >
              {t('buttons:buttons.reminder')}
              <br />
              {t('buttons:buttons.share-badge')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={openEditDialog} onClose={closeEdit} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>{t('dialogs:dialog.edit-group-badge-issue')}</DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Typography gutterBottom>{t('dialogs:dialog.badge-group-issue-name')}</Typography>
            <FormControl className="w-100">
              <TextField
                autoFocus
                required
                id="nombre"
                name="nombre"
                variant="outlined"
                onChange={handleInput}
                value={form.nombre}
              />
            </FormControl>
            <div className={`text-center ${classes.root}`} style={{ marginTop: '1.5em' }}>
              <Button disableElevation variant="contained" className={`my-2 ${classes.button}`} type="submit">
                {t('buttons:buttons.edit')}
              </Button>
              <Button disableElevation variant="contained" className={`my-2 ${classes.button}`} onClick={closeEdit}>
                {t('buttons:buttons.cancel')}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop loading={loadingBackdrop} />
    </>
  );
}

const useStyles = makeStyles(() => ({
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    margin: '0.5em',
    color: 'white',
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: '10px 40px',
    '&:hover': {
      backgroundColor: colors.secondary,
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    page: state.page,
    rowsPerPage: state.rowsPerPage,
    permission: (state.permission || [])
      .filter((data) => data.acciones?.modulos?.nombre.toLowerCase() === 'insignias emitidas')
      .map((item) => item.acciones.nombre),
    user: state.user,
    organizationId: state.organizationId || state.user.data_user.gruposUsuariosOrganizaciones[0].id_organizaciones,
    searchValue: state.searchValue,
  };
};

const mapDispatchToProps = {
  changePage,
  changeBreadcrump,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsBadgesIssued);
