import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, MenuItem, Menu, Divider, Drawer, Tooltip, Avatar } from '@material-ui/core';
import {
  VpnKeyOutlined,
  DashboardOutlined,
  PeopleOutlined,
  CloseOutlined,
  MoreVertOutlined,
  MenuOutlined,
  ExpandMoreOutlined,
  ManageAccountsOutlined,
  RepeatOutlined,
  LogoutOutlined,
} from '@mui/icons-material';
import Sidebar from './Menu';
import Language from './Languaje';
import Notifications from './Notifications';
import colors from '../assets/styles/colors';
import axios from '../api';
import { logoutRequest, changePermissions, setOrganizationId } from '../actions';
import { BroadcastChannel } from 'broadcast-channel';
import { env } from '../config/environment';
/* Translations */
import { useTranslation } from 'react-i18next';

const Appbar = (props) => {
  const { changePermissions, user, setOrganizationId, closeButton, handleClose, styles, signature } = props;
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorElOrganization, setAnchorElOrganization] = useState(null);
  const logoutChannel = new BroadcastChannel('logout');

  /* translation */
  const [t] = useTranslation(['appBar', 'alerts', 'buttons']);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorElOrganization(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClickMenuOrganization = (event) => {
    setAnchorElOrganization(event.currentTarget);
  };

  const handleMenuOrganization = async (id_organizaciones) => {
    const { data } = await axios.post(`/permission/getPermissions`, {
      id_organizacion: id_organizaciones,
    });

    changePermissions(data.permissions.permissions);
    setOrganizationId(id_organizaciones);
    setAnchorEl(null);
    setAnchorElOrganization(null);
    const esEmisor = user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 4;
    if (esEmisor) {
      history.push('/issue');
    } else if (id_organizaciones === env.idMinciencias) {
      history.push('/certificates/published');
    } else {
      history.push('/templates/published');
    }
  };

  const toLink = (ruta) => {
    let enlace = document.createElement('a');
    enlace.href = ruta;
    enlace.click();
  };

  const handleSesion = () => {
    setAnchorEl(false);
    Swal.fire({
      text: t('alerts:alerts.exit'),
      icon: 'question',
      iconColor: colors.primary,
      showCancelButton: true,
      confirmButtonText: t('buttons:buttons.exit'),
      cancelButtonText: t('buttons:buttons.cancel'),
      buttonsStyling: false,
      customClass: {
        container: classes.mySwal,
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    }).then(async (result) => {
      if (result.value) {
        if (window.location.pathname.includes('dashboard') || window.location.pathname.includes('organization')) {
          logoutRequest();
          changePermissions();
          history.push('/');
          logoutChannel.postMessage('CerrarSesion');
        } else {
          logoutChannel.postMessage('CerrarSesion');
        }
      }
    });
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div className={classes.container__profile}>
        <Avatar src={user?.data_user?.linkPhoto} className={classes.container__avatar} />
        <div className={classes.container__welcome}>
          <p className={classes.container__welcome_message}>{t('appBar.welcome')}</p>
          <p
            className={classes.container__welcome_name}
          >{`${user?.data_user?.primer_nombre} ${user?.data_user?.primer_apellido}`}</p>
        </div>
      </div>
      <Divider />
      <MenuItem className={classes.textMenu} onClick={(e) => toLink('/dashboard')}>
        <DashboardOutlined sx={iconStyle} />
        {t('appBar.dashboard')}
      </MenuItem>
      <MenuItem className={classes.textMenu} onClick={(e) => toLink('/account/changePassword')}>
        <VpnKeyOutlined sx={iconStyle} />
        {t('appBar.change-password')}
      </MenuItem>
      <MenuItem className={classes.textMenu} onClick={(e) => toLink('/profileSettings')}>
        <ManageAccountsOutlined sx={iconStyle} />
        {t('appBar.edit-profile')}
      </MenuItem>
      <MenuItem className={classes.textMenu} onClick={(e) => toLink('/account/combine')}>
        <PeopleOutlined sx={iconStyle} />
        {t('appBar.combination')}
      </MenuItem>
      {user?.data_user?.gruposUsuariosOrganizaciones.some(({ id_organizaciones }) => id_organizaciones > 0) &&
        user?.data_user?.gruposUsuariosOrganizaciones[0].id_grupos_usuarios !== 3 && (
          <MenuItem
            className={classes.textMenu}
            onClick={
              user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 1
                ? () => toLink('/templates/published')
                : user?.data_user?.gruposUsuariosOrganizaciones.length > 1
                  ? handleClickMenuOrganization
                  : () => handleMenuOrganization(user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_organizaciones)
            }
          >
            <RepeatOutlined sx={iconStyle} />
            {t('appBar.admin')}
          </MenuItem>
        )}
      <Divider />
      <MenuItem className={classes.textMenu} onClick={handleSesion}>
        <LogoutOutlined sx={iconStyle} />
        {t('appBar.logout')}
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {user ? (
        <>
          <div className={classes.container__profile}>
            <Avatar src={user?.data_user?.linkPhoto} className={classes.container__avatar} />
            <div className={classes.container__welcome}>
              <p className={classes.container__welcome_message}>{t('appBar.welcome')}</p>
              <p
                className={classes.container__welcome_name}
              >{`${user?.data_user?.primer_nombre} ${user?.data_user?.primer_apellido}`}</p>
            </div>
          </div>
          <Divider />
          <MenuItem className={classes.textMenu} onClick={(e) => toLink('/dashboard')}>
            <DashboardOutlined sx={iconStyle} />
            {t('appBar.dashboard')}
          </MenuItem>
          <MenuItem className={classes.textMenu} onClick={(e) => toLink('/account/changePassword')}>
            <VpnKeyOutlined sx={iconStyle} />
            {t('appBar.change-password')}
          </MenuItem>
          <MenuItem className={classes.textMenu} onClick={(e) => toLink('/profileSettings')}>
            <ManageAccountsOutlined sx={iconStyle} />
            {t('appBar.edit-profile')}
          </MenuItem>
          <MenuItem className={classes.textMenu} onClick={(e) => toLink('/account/combine')}>
            <PeopleOutlined sx={iconStyle} />
            {t('appBar.combination')}
          </MenuItem>
          {user?.data_user?.gruposUsuariosOrganizaciones.some(({ id_organizaciones }) => id_organizaciones > 0) && (
            <MenuItem
              className={classes.textMenu}
              onClick={
                user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios === 1
                  ? () => toLink('/templates/published')
                  : user?.data_user?.gruposUsuariosOrganizaciones.length > 1
                    ? handleClickMenuOrganization
                    : () => handleMenuOrganization(user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_organizaciones)
              }
            >
              <RepeatOutlined sx={iconStyle} />
              {t('appBar.admin')}
            </MenuItem>
          )}
          <Divider />
          <MenuItem className={classes.textMenu} onClick={handleSesion}>
            <LogoutOutlined sx={iconStyle} />
            {t('appBar.logout')}
          </MenuItem>
        </>
      ) : (
        <MenuItem className={classes.textMenu} onClick={() => history.push('/sign-in')}>
          <LogoutOutlined sx={iconStyle} />
          {t('buttons:buttons.sign-in')}
        </MenuItem>
      )}
    </Menu>
  );

  const renderMenuOrganization = (
    <Menu
      id="simple-menu"
      anchorEl={anchorElOrganization}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorElOrganization)}
      onClose={handleMenuClose}
    >
      {user?.data_user?.gruposUsuariosOrganizaciones.map((item) => (
        <MenuItem
          key={`menu-${item.id}`}
          className={classes.textMenu}
          onClick={() => handleMenuOrganization(item.id_organizaciones)}
        >
          {item.organizaciones?.nombre}
        </MenuItem>
      ))}
    </Menu>
  );

  const toggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar className={classes.appbar} position="static" elevation={0}>
        <Toolbar>
          {user && user.data_user?.gruposUsuariosOrganizaciones[0]?.id_grupos_usuarios !== 3 && (
            <div className={classes.appbar__toolbar_sectionMobile}>
              <IconButton edge="start" onClick={toggleDrawer} color="#000" aria-label="menu">
                <MenuOutlined />
              </IconButton>
            </div>
          )}
          <div className={classes.appbar__toolbar_logocontainer}>
            <img src={styles?.logo_blanco} alt="logo" className={classes.appbar__toolbar_logocontainer_img} />
          </div>

          <Language />

          {user ? (
            <>
              {/* {user?.data_user?.gruposUsuariosOrganizaciones[0]?.id_organizaciones ? (
                ''
              ) : (
                <div className={classes.appbar__toolbar_sectionDesktop}>
                  <Button className={classes.buttonOrganization} onClick={() => history.push('/organizations/create')}>
                    <span>Crea tu organizacion</span>
                  </Button>
                </div>
              )} */}

              <Notifications />

              <div className={classes.appbar__toolbar_sectionDesktop}>
                <IconButton
                  className={classes.accountCircle}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="#000"
                >
                  <Avatar src={user?.data_user?.linkPhoto} />
                  <ExpandMoreOutlined />
                </IconButton>
                {closeButton && (
                  <Tooltip title="Cerrar">
                    <IconButton className={classes.buttonClose} onClick={() => handleClose(false)}>
                      <CloseOutlined />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </>
          ) : closeButton ? (
            <Tooltip title="Cerrar">
              <IconButton className={classes.buttonClose_setionDesktop} onClick={() => handleClose(false)}>
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          ) : (
            !signature && (
              <button className={classes.appbar__toolbar_button_session} onClick={() => history.push('/sign-in')}>
                {t('buttons:buttons.sign-in')}
              </button>
            )
          )}

          <div className={classes.appbar__toolbar_sectionMobile}>
            {closeButton ? (
              <Tooltip title="Cerrar">
                <IconButton className={classes.buttonClose} onClick={() => handleClose(false)}>
                  <CloseOutlined />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreVertOutlined />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderMenuOrganization}
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor={'left'}
        open={mobileOpen}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawer__paper,
        }}
        color="#000"
      >
        <Sidebar />
      </Drawer>
    </>
  );
};

const iconStyle = {
  fontSize: '1em',
  marginRight: '.5em',
};

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.main,
  },
  appbar__toolbar_logocontainer: {
    width: '100%',
    marginLeft: '0px',
  },
  button: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: colors.footer,
    },
  },
  buttonOrganization: {
    color: colors.primary,
    backgroundColor: 'white',
    borderRadius: 5,
    margin: '8px 100px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appbar__toolbar_logocontainer_img: {
    display: 'block',
    width: '8em',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: '12em',
      //marginLeft: '4em',
    },
  },
  appbar__toolbar_sectionDesktop: {
    color: '#ffffff',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      //position: 'absolute',
      right: '2vw',
    },
  },
  appbar__toolbar_sectionMobile: {
    display: 'flex',
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar__toolbar_button_session: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '10em',
      height: 32,
      color: theme.palette.primary.main,
      border: '2px solid',
      borderRadius: 20,
      background: 'transparent',
      fontWeight: 'bold',
      '&:hover': {
        color: '#4a4a4a',
      },
    },
  },
  icon: {
    fontSize: '1em',
    marginRight: '.5em',
  },
  textMenu: {
    //color: '#000',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawer__paper: {
    width: 240,
    backgroundColor: colors.background,
  },
  buttonClose: {
    margin: '.9em .1em .9em 0',
    float: 'right',
    color: '#ffffff',
    backgroundColor: colors.secondary,
  },
  buttonClose_setionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      margin: '.9em .1em 0.9em 0',
      float: 'right',
      color: '#ffffff',
      backgroundColor: colors.secondary,
    },
  },
  mySwal: {
    zIndex: 3000,
  },
  accountCircle: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  container__profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'initial',
      alignItems: 'initial',
    },
  },
  container__avatar: {
    borderRadius: '100%',
    margin: '.5em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  container__welcome: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 .5em -1em .5em',
  },
  container__welcome_message: {
    margin: 0,
  },
  container__welcome_name: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '1px',
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
    styles: state.styles,
  };
};

const mapDispatchToProps = {
  changePermissions,
  setOrganizationId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
